<template>
	<div
		data-component="input-field"
		:data-size="size"
		:data-layout="orientation"
		:data-validationsize="validationSize"
		:data-validationposition="validationPosition"
	>
		<validation-provider
			tag="div"
			v-slot="{ errors, required }"
			:rules="rules"
			:vid="getVid"
			:name="getName"
		>
			<label
				data-element="label"
				v-if="getLabel"
				:for="getId"
				:data-required="required"
				:data-error="errors && errors[0]"
				v-html="getLabel"
			/>
			<input
				:id="getId"
				:type="type"
				:value="value"
				:step="step"
				:min="min"
				:max="max"
				:autocomplete="autocomplete"
				:readonly="readonly"
				:data-error="errors && errors[0]"
				@input="onInput"
				@focus="$emit('focus')"
				:placeholder="getPlaceholder"
			/>
			<p
				v-if="errors[0] && showValidation"
				data-element="error"
			>
				{{ errors[0] }}
			</p>
		</validation-provider>
	</div>
</template>

<script>

	import { paramCase }  from 'change-case';
	import { camelCase }  from 'change-case';

	export default {
		props: {
			name: {
				type: String,
				required: true
			},
			id: {
				type: String,
				default: undefined
			},
			readonly: {
				type: Boolean,
				default: undefined
			},
			type: {
				type: String,
				required: true
			},
			min: {
				type: [Number, String],
				default: undefined
			},
			max: {
				type: [Number, String],
				default: undefined
			},
			step: {
				type: Number,
				default: 1
			},
			autocomplete: {
				type: String,
				default: undefined
			},
			size: {
				type: String,
				default: 'md'
			},
			orientation: {
				type: String,
				default: undefined
			},
			validationSize: {
				type: String,
				default: 'md'
			},
			validationPosition: {
				type: String,
				default: 'below'
			},
			rules: {
				type: [
					String,
					Object
				],
				default: ''
			},
			value: {
				type: [
					String,
					Number
				],
				default: undefined
			},
			placeholder: {
				type: String,
				default: ''
			},
			showLabel: {
				type: Boolean,
				default: true
			},
			showValidation: {
				type: Boolean,
				default: true
			}
		},
		data: () => ({}),
		computed: {
			getName () {
				return this.name;
			},
			getLabel () {
				if (!this.showLabel) {
					return false;
				}
				return this.name;
			},
			getId () {
				if (!this.id) {
					return paramCase(this.getName);
				}
				return this.id;
			},
			getVid () {
				if (!this.id) {
					return camelCase(this.getName);
				}
				return this.id;
			},
			getPlaceholder () {
				if (!this.placeholder &&
						!this.placeholder.length) {
					return false;
				}
				return this.placeholder;
			}
		},
		methods: {
			onInput (event) {
				this.$emit('input', event.target.value);
			}
		}
	};

</script>

<style lang="scss" scoped>

  [data-component='input-field'] {
		>div {
			width:100%;
			input {
				width:100%;
				outline:none;
				border:1px solid $c-brand-grey-lighter-7;
				border-radius:5px;
				font-size:rem(18);
				line-height:32px;
				color:$c-brand-black;
				&:placeholder-shown {
					text-overflow: ellipsis;
				}
				&:hover,
				&:focus {
					border:1px solid $c-brand-grey-lighter-2;
				}
				&[data-error] {
					border:1px solid $c-red;
				}
				&:read-only {
					pointer-events: none;
				}
				&::-webkit-search-cancel-button {
					display: none;
				}
			}
		}
		&[data-size='md'] {
			input {
				height:rem(40);
				padding:0 rem(10);
			}
		}
		&[data-size='xs'] {
			padding:0;
			margin:0;
			input {
				height:rem(28);
			}
		}
	}

</style>
